.container_fw {
  height: var(--header-size);
  width: var(--desktop);
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: space-around;
  &::after {
    content: "";
    height: var(--header-size);
    z-index: -1;
    left: 0;
    width: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
  }
  & div {
    display: flex;
    flex: 1;
    justify-content: center;
    &:first-child {
      justify-content: flex-start;
    }
    &:last-child {
      flex: 2;
      justify-content: flex-end;
    }
  }
  & p {
    color: var(--white);
  }
  & .email {
    color: var(--white);
    text-decoration: none;
  }
  &_mobile {
    @extend .container_fw;
    width: var(--mobile);
    min-height: var(--header-mobile-size);
    &::after {
      height: var(--header-mobile-size);
    }
    & div {
      font-size: var(--box_header);
    }
  }
}

.container_fw {
  width: var(--desktop);
  height: var(--header-size);
  display: flex;
  align-self: center;
  align-items: center;
  &::after {
    content: "";
    height: var(--header-size);
    z-index: -1;
    left: 0;
    width: 100%;
    position: absolute;
    background-color: var(--black);
  }

  .social {
    flex: 1;
    display: flex;
    gap: var(--padding-xl);
  }

  // MOBILE
  &_mobile {
    @extend .container_fw;
    width: var(--mobile);
    min-height: var(--header-mobile-size);
    &::after {
      height: calc(var(--header-size) / 2);
    }
    & .language .current {
      font-size: var(--box_header);
    }
  }
}

.hoveredIcon {
  display: flex;
  justify-content: center;
  gap: var(--padding-xl);
  align-items: center;
  transition: all 0.5s ease-in-out;
  & svg {
    height: var(--icon-size);
    width: var(--icon-size);
    fill: var(--white);
    transition: all 0.5s ease-in-out;
  }
  &:hover {
    cursor: pointer;
    & svg {
      fill: var(--accent-color);
    }
  }
  //   MOBILE
  &_mobile {
    @extend .hoveredIcon;
    & svg {
      height: calc(var(--icon-size) / 2);
      width: calc(var(--icon-size) / 2);
    }
  }
}

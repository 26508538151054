.container, .container_mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: inherit;
  position: relative;
}
.container .logo, .container_mobile .logo {
  height: 30vh;
}
.container .brand, .container_mobile .brand {
  color: #000;
  font-size: var(--label);
  font-family: AlexBrush;
}
.container_mobile .logo {
  height: 20vh;
}/*# sourceMappingURL=Brand.module.css.map */
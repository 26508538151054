.container_fw, .container_fw_mobile {
  width: var(--desktop);
  height: var(--header-size);
  display: flex;
  align-self: center;
  align-items: center;
}
.container_fw::after, .container_fw_mobile::after {
  content: "";
  height: var(--header-size);
  z-index: -1;
  left: 0;
  width: 100%;
  position: absolute;
  background-color: var(--black);
}
.container_fw .social, .container_fw_mobile .social {
  flex: 1;
  display: flex;
  gap: var(--padding-xl);
}
.container_fw_mobile {
  width: var(--mobile);
  min-height: var(--header-mobile-size);
}
.container_fw_mobile::after {
  height: calc(var(--header-size) / 2);
}
.container_fw_mobile .language .current {
  font-size: var(--box_header);
}

.hoveredIcon, .hoveredIcon_mobile {
  display: flex;
  justify-content: center;
  gap: var(--padding-xl);
  align-items: center;
  transition: all 0.5s ease-in-out;
}
.hoveredIcon svg, .hoveredIcon_mobile svg {
  height: var(--icon-size);
  width: var(--icon-size);
  fill: var(--white);
  transition: all 0.5s ease-in-out;
}
.hoveredIcon:hover, .hoveredIcon_mobile:hover {
  cursor: pointer;
}
.hoveredIcon:hover svg, .hoveredIcon_mobile:hover svg {
  fill: var(--accent-color);
}
.hoveredIcon_mobile svg {
  height: calc(var(--icon-size) / 2);
  width: calc(var(--icon-size) / 2);
}/*# sourceMappingURL=Header.module.css.map */
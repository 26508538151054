.block, .block_custom, .block_mobile {
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  box-sizing: border-box;
  color: #fff;
}
.block .externalLink, .block_custom .externalLink, .block_mobile .externalLink {
  position: absolute;
  z-index: 5;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.block .bg, .block_custom .bg, .block_mobile .bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  background-size: cover;
  background-position: 50%;
  background-color: #cccccc;
  z-index: 0;
  transition: all 0.5s ease-in-out;
}
.block .overlay, .block_custom .overlay, .block_mobile .overlay {
  border: 4px solid transparent;
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: all 0.5s ease-in-out;
  background-color: rgba(0, 0, 0, 0.6);
}
.block .icon, .block_custom .icon, .block_mobile .icon {
  position: absolute;
  padding: var(--padding-md);
  border-radius: 100%;
  z-index: 3;
  top: calc(50% + var(--icon-size));
  transition: all 0.5s ease-in-out;
}
.block .icon svg, .block_custom .icon svg, .block_mobile .icon svg {
  fill: var(--white);
  height: var(--icon-size);
  width: var(--icon-size);
}
.block .content, .block_custom .content, .block_mobile .content {
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  transition: all 0.5s ease-in-out;
}
.block .content .text, .block_custom .content .text, .block_mobile .content .text {
  position: absolute;
  left: 0;
  width: 100%;
  color: #fff;
  top: calc(50% - var(--label));
  transition: all 0.5s ease-in-out;
  font-size: var(--label);
  text-align: center;
}
.block .content .label, .block_custom .content .label, .block_mobile .content .label {
  color: #fff;
  transition: all 0.5s ease-in-out;
  flex-direction: column;
  font-size: 22px;
  position: absolute;
  width: 100%;
  left: 0;
  text-align: center;
  bottom: -50%;
}
.block:hover .overlay, .block_custom:hover .overlay, .block_mobile:hover .overlay {
  border-color: var(--accent-color);
}
.block:hover > .bg, .block_custom:hover > .bg, .block_mobile:hover > .bg {
  transform: scale(1.1);
}
.block:hover > .overlay, .block_custom:hover > .overlay, .block_mobile:hover > .overlay {
  background-color: rgba(0, 0, 0, 0.9);
}
.block:hover > .content .text, .block_custom:hover > .content .text, .block_mobile:hover > .content .text {
  top: 20px;
}
.block:hover > .content .label, .block_custom:hover > .content .label, .block_mobile:hover > .content .label {
  bottom: 5%;
}
.block:hover > .icon, .block_custom:hover > .icon, .block_mobile:hover > .icon {
  top: calc(50% - var(--icon-size));
  border-color: var(--accent-color);
}
.block:hover > .icon svg, .block_custom:hover > .icon svg, .block_mobile:hover > .icon svg {
  fill: var(--accent-color);
}
.block_mobile .content .text {
  font-size: var(--comment);
}
.block_mobile .content .label {
  font-size: var(--comment);
}
.block_mobile .icon {
  top: 50%;
}
.block_mobile .icon svg {
  height: calc(var(--icon-size) / 2);
  width: calc(var(--icon-size) / 2);
}

.block_custom {
  justify-content: center;
  align-items: center;
  cursor: default;
}/*# sourceMappingURL=Box.module.css.map */
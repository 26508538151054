.container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  width: 100%;
  align-items: center;
  font-size: var(--comment);
  & .icon {
    padding-bottom: var(--padding-xl);
    & svg path {
      fill: #fff;
    }
  }
  & svg {
    height: var(--icon-size);
    width: var(--icon-size);
  }

  & .phones_container {
    display: flex;
    flex-direction: column;
    align-items: center;

    & .phones {
      display: flex;
      flex-direction: column;
      gap: 10px;
      border-bottom: 1px solid var(--accent-color);
      padding-bottom: var(--padding-xl);

      & a {
        text-decoration: none;
        color: var(--comment);
        font-weight: 500;
      }
    }
  }

  & .contact_icons {
    padding-top: var(--padding-xl);
    display: flex;
    gap: var(--padding-xl);
  }

  &_mobile {
    @extend .container;
    font-size: var(--box_header);
  }
}

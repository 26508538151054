.container, .container_mobile {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  width: 100%;
  align-items: center;
  font-size: var(--comment);
}
.container .icon, .container_mobile .icon {
  padding-bottom: var(--padding-xl);
}
.container .icon svg path, .container_mobile .icon svg path {
  fill: #fff;
}
.container svg, .container_mobile svg {
  height: var(--icon-size);
  width: var(--icon-size);
}
.container .phones_container, .container_mobile .phones_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.container .phones_container .phones, .container_mobile .phones_container .phones {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-bottom: 1px solid var(--accent-color);
  padding-bottom: var(--padding-xl);
}
.container .phones_container .phones a, .container_mobile .phones_container .phones a {
  text-decoration: none;
  color: var(--comment);
  font-weight: 500;
}
.container .contact_icons, .container_mobile .contact_icons {
  padding-top: var(--padding-xl);
  display: flex;
  gap: var(--padding-xl);
}
.container_mobile {
  font-size: var(--box_header);
}/*# sourceMappingURL=Contacts.module.css.map */
.container {
  display: flex;
  gap: calc(var(--padding-xl) * 2);
  & .lang {
    color: var(--white);
    opacity: 1;
    transition: all 1s ease;
    border-bottom: 1px solid transparent;
    font-size: var(--default);
    text-transform: uppercase;
    &:hover {
      cursor: pointer;
      color: var(--accent-color);
      opacity: 0.6;
    }
    &_active {
      @extend .lang;
      opacity: 1;
      color: var(--accent-color);
    }
  }
  &_mobile {
    @extend .container;
    & .lang {
      font-size: var(--box_header);
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
  overflow: hidden;
  justify-content: space-between;
  margin: auto;
  &_auto {
    @extend .container;
    height: "auto";
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: inherit;
  position: relative;
  .logo {
    height: 30vh;
  }

  .brand {
    color: #000;
    font-size: var(--label);
    font-family: AlexBrush;
  }

  &_mobile {
    @extend .container;
    & .logo {
      height: 20vh;
    }
    & .brand {
    }
  }
}

.container, .container_auto {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
  overflow: hidden;
  justify-content: space-between;
  margin: auto;
}
.container_auto {
  height: "auto";
}/*# sourceMappingURL=MainContainer.module.css.map */
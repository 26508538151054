.container {
  display: grid;
  gap: var(--padding-md);
  height: inherit;
  width: var(--desktop);
  grid-template-columns: repeat(3, minmax(33%, auto));
  grid-template-rows: repeat(2, 1fr);

  &_mobile {
    @extend .container;
    width: var(--mobile);
    height: auto;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
}

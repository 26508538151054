.container, .container_mobile {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: var(--white);
  padding: var(--padding-xl) 0;
}
.container_mobile {
  height: calc(100% - var(--header-mobile-size) * 2);
}/*# sourceMappingURL=BodyContainer.module.css.map */
.block {
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  box-sizing: border-box;
  color: #fff;
  & .externalLink {
    position: absolute;
    z-index: 5;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  & .bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-size: cover;
    background-position: 50%;
    background-color: #cccccc;
    z-index: 0;
    transition: all 0.5s ease-in-out;
  }
  & .overlay {
    border: 4px solid transparent;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: all 0.5s ease-in-out;
    background-color: rgba(0, 0, 0, 0.6);
  }
  & .icon {
    position: absolute;
    // border: 1px solid var(--white);
    padding: var(--padding-md);
    border-radius: 100%;
    z-index: 3;
    top: calc(50% + var(--icon-size));
    transition: all 0.5s ease-in-out;
    & svg {
      fill: var(--white);
      height: var(--icon-size);
      width: var(--icon-size);
    }
  }
  & .content {
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    transition: all 0.5s ease-in-out;

    & .text {
      position: absolute;
      left: 0;
      width: 100%;
      color: #fff;
      top: calc(50% - var(--label));
      transition: all 0.5s ease-in-out;
      font-size: var(--label);
      text-align: center;
    }
    & .label {
      color: #fff;
      transition: all 0.5s ease-in-out;
      flex-direction: column;
      font-size: 22px;
      position: absolute;
      width: 100%;
      left: 0;
      text-align: center;
      bottom: -50%;
    }
  }
  &:hover {
    & .overlay {
      border-color: var(--accent-color);
    }
  }
  &:hover > .bg {
    transform: scale(1.1);
  }
  &:hover > .overlay {
    background-color: rgba(0, 0, 0, 0.9);
  }
  &:hover > .content {
    & .text {
      top: 20px;
    }
    & .label {
      bottom: 5%;
    }
  }
  &:hover > .icon {
    top: calc(50% - var(--icon-size));
    border-color: var(--accent-color);
    & svg {
      fill: var(--accent-color);
    }
  }

  &_mobile {
    @extend .block;
    & .content {
      & .text {
        font-size: var(--comment);
      }
      & .label {
        font-size: var(--comment);
      }
    }
    & .icon {
      top: 50%;
      & svg {
        height: calc(var(--icon-size) / 2);
        width: calc(var(--icon-size) / 2);
      }
    }
  }
}

.block_custom {
  @extend .block;
  justify-content: center;
  align-items: center;
  cursor: default;
}

.serviceLayout, .serviceLayout_mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0;
  height: 100%;
  gap: 0 var(--padding-md);
}
.serviceLayout .priceHeader, .serviceLayout_mobile .priceHeader {
  padding: calc(var(--padding-xl) * 1.5) calc(var(--padding-xl));
  background: rgb(255, 255, 255);
  background: linear-gradient(90deg, rgb(0, 0, 0) 5%, rgba(0, 0, 0, 0.5) 40%, rgb(255, 255, 255) 100%);
  margin-bottom: var(--padding-xl);
}
.serviceLayout .priceHeader .title, .serviceLayout_mobile .priceHeader .title {
  color: var(--white);
  font-size: var(--label);
}
.serviceLayout .prices, .serviceLayout_mobile .prices {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.serviceLayout .prices .price, .serviceLayout_mobile .prices .price {
  display: flex;
  color: var(--black);
  padding: var(--padding-md) var(--padding-xl);
  border-bottom: 1px solid var(--lightgrey);
  justify-content: space-between;
}
.serviceLayout .prices .price:last-child, .serviceLayout_mobile .prices .price:last-child {
  border-bottom: none;
}
.serviceLayout .links, .serviceLayout_mobile .links {
  display: flex;
  flex-direction: column;
  gap: var(--padding-xl);
}
.serviceLayout .links .link, .serviceLayout_mobile .links .link {
  cursor: pointer;
}
.serviceLayout .links .link_text, .serviceLayout_mobile .links .link_text {
  font-size: 24px;
}
.serviceLayout .links .link_active, .serviceLayout_mobile .links .link_active {
  color: var(--accent-color);
}
.serviceLayout_mobile {
  padding: var(--padding-md) 0;
  align-items: flex-start;
}

.boxContainer {
  display: grid;
  gap: var(--padding-md);
  height: inherit;
  width: var(--desktop);
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas: "A B B" "C B B";
}
.boxContainer > div:nth-child(1) {
  grid-area: A;
}
.boxContainer > div:nth-child(3) {
  grid-area: C;
}
.boxContainer > div:nth-child(2) {
  grid-area: B;
}
.boxContainer_mobile {
  width: var(--mobile);
  grid-template-rows: min-content min-content;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: "A C" "B B";
}
.boxContainer_mobile > div:nth-child(2) {
  max-height: -moz-min-content;
  max-height: min-content;
}
.boxContainer_mobile .links {
  padding: calc(var(--padding-xl) * 2) 0;
  gap: calc(var(--padding-xl) * 1.5);
}
.boxContainer_mobile .links .link {
  cursor: pointer;
}
.boxContainer_mobile .links .link_text {
  font-size: var(--comment);
}
.boxContainer_mobile .priceHeader {
  padding: var(--padding-md);
}
.boxContainer_mobile .priceHeader .title {
  font-size: var(--default);
}
.boxContainer_mobile .price_list {
  overflow: scroll;
}
.boxContainer_mobile .prices .price {
  padding: var(--padding-md);
}
.boxContainer_mobile .prices .price p:first-child {
  flex: 1;
  padding-right: var(--padding-xl);
}/*# sourceMappingURL=Services.module.css.map */
* {
  font-family: Comfortaa;
}
div#root {
  max-width: 100vw;
  overflow: hidden;
  position: relative;
}
div#root > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--black);
}
ul {
  margin: 0;
  padding: 0;
}
li {
  list-style-type: none;
}
p {
  margin: 0;
  padding: 0;
}
.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
:root {
  /* COLORS */
  --black: #000;
  --accent-color: #c2a058;
  --white: #fff;
  --lightgrey: #f4f0f0;

  /* SCREEN MAX WIDTH */
  --desktop: 1280px;
  --tablet: 560px;
  --mobile: 360px;

  /* SIZE CONSTS  */
  --icon-size: 2.2rem;
  --header-size: 80px;
  --header-mobile-size: calc(var(--header-size) / 2);

  /* FONT SIZE */
  --box_header: 14px;
  --comment: 18px;
  --default: 24px;
  --label: 32px;

  /* PADDINGS */
  --padding-sm: 4px;
  --padding-md: 8px;
  --padding-lg: 12px;
  --padding-xl: 18px;
}

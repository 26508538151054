.container {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: var(--padding-xl);
  font-size: var(--comment);
  & .icon {
    & svg {
      height: var(--icon-size);
      width: var(--icon-size);
      & path {
        fill: #fff;
      }
    }
  }
  & .address {
    border-bottom: 1px solid var(--accent-color);
    padding-bottom: var(--padding-xl);
    line-height: 1.6rem;
  }

  &_mobile {
    @extend .container;
    font-size: var(--box_header);
  }
}

.container {
    z-index: 10;
}

.closeButton {
    position: absolute;
    width: 50px;
    height: 50px;
    right: 25px;
    top: 25px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000;
    z-index: 11;
    cursor: pointer;

    &:hover {
        & svg {
            fill: var(--accent-color)
        }
    }
}
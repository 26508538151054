.serviceLayout {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0;
  height: 100%;
  gap: 0 var(--padding-md);

  & .priceHeader {
    padding: calc(var(--padding-xl) * 1.5) calc(var(--padding-xl));
    background: rgb(255, 255, 255);
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 1) 5%,
      rgba(0, 0, 0, 0.5) 40%,
      rgba(255, 255, 255, 1) 100%
    );
    margin-bottom: var(--padding-xl);
    & .title {
      color: var(--white);
      font-size: var(--label);
    }
  }
  & .prices {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    & .price {
      display: flex;
      color: var(--black);
      padding: var(--padding-md) var(--padding-xl);
      border-bottom: 1px solid var(--lightgrey);
      justify-content: space-between;
      &:last-child {
        border-bottom: none;
      }
    }
  }
  & .links {
    display: flex;
    flex-direction: column;
    gap: var(--padding-xl);
    & .link {
      cursor: pointer;
      &_text {
        font-size: 24px;
      }
      &_active {
        color: var(--accent-color);
      }
    }
  }
  &_mobile {
    @extend .serviceLayout;
    padding: var(--padding-md) 0;
    align-items: flex-start;
  }
}

.boxContainer {
  display: grid;
  gap: var(--padding-md);
  height: inherit;
  width: var(--desktop);
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas: "A B B" "C B B";
  & > div {
    &:nth-child(1) {
      grid-area: A;
    }
    &:nth-child(3) {
      grid-area: C;
    }
    &:nth-child(2) {
      grid-area: B;
    }
  }
  &_mobile {
    width: var(--mobile);
    grid-template-rows: min-content min-content;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: "A C" "B B";
    & > div {
      &:nth-child(2) {
        max-height: min-content;
      }
    }
    & .links {
      padding: calc(var(--padding-xl) * 2) 0;
      gap: calc(var(--padding-xl) * 1.5);
      & .link {
        cursor: pointer;
        &_text {
          font-size: var(--comment);
        }
      }
    }
    & .priceHeader {
      padding: var(--padding-md);
      & .title {
        font-size: var(--default);
      }
    }
    & .price_list {
      overflow: scroll;
    }
    & .prices {
      .price {
        padding: var(--padding-md);
        & p:first-child {
          flex: 1;
          padding-right: var(--padding-xl);
        }
      }
    }
  }
}
